<template>
    <div class="table-operation">
        <div v-if="slots.length == 0">
            <slot></slot>
        </div>
        <div v-else-if="slots.length > 0 && slots.length <= 2" class="table-operation-btns" >
            <template v-for="(vnode, index) in slots">
                <el-tooltip  :content="'如需操作，请联系相关运营人员'" placement="top" :key="index" v-if="disabled && disabled === true">
                    <el-button
                        :key="index"
                        :class="isDeleteBtn(vnode) ? 'table-operation-btn-delete' : 'table-operation-btn-primary'"
                        type="text"
                        :disabled="true"
                        @click="vnode.componentOptions.listeners.click"
                        :style="vnode.data.staticStyle"
                    >{{ vnode.elm.innerText }}</el-button>
                </el-tooltip>
                <el-button
                    v-else
                    :key="index"
                    :class="isDeleteBtn(vnode) ? 'table-operation-btn-delete' : 'table-operation-btn-primary'"
                    type="text"
                    :disabled="(vnode.componentOptions && vnode.componentOptions.propsData && vnode.componentOptions.propsData.disabled === true) ? true : false"
                    @click="vnode.componentOptions.listeners.click"
                    :style="vnode.data.staticStyle"
                >{{ vnode.elm.innerText }}</el-button>
                
            </template>
        </div>
        
        <div v-else-if="slots.length > 2" class="table-operation-btns" >
            <el-tooltip  :content="'如需操作，请联系相关运营人员'" placement="top" :key="index" v-if="disabled && disabled === true">
                <el-button
                    :key="+new Date()"
                    :class="isDeleteBtn(slots[0]) ? 'table-operation-btn-delete' : 'table-operation-btn-primary'"
                    type="text"
                    :disabled="true"
                    @click="slots[0].componentOptions.listeners.click"
                    :style="slots[0].data.staticStyle"
                >{{ slots[0].elm.innerText }}</el-button>
            </el-tooltip>
            <el-button
                v-else
                :key="+new Date()"
                :class="isDeleteBtn(slots[0]) ? 'table-operation-btn-delete' : 'table-operation-btn-primary'"
                type="text"
                :disabled="(slots[0].componentOptions && slots[0].componentOptions.propsData && slots[0].componentOptions.propsData.disabled === true) ? true : false"
                @click="slots[0].componentOptions.listeners.click"
                :style="slots[0].data.staticStyle"
            >{{ slots[0].elm.innerText }}</el-button>

            <el-dropdown class="table-operation-dropdown">
                <div class="table-operation-more">
                    <span class="table-operation-more-label">更多</span>
                    <i class="el-icon-arrow-down table-operation-more-icon"></i>
                </div>
                <el-dropdown-menu class="table-operation-dropdown-menu" slot="dropdown" :append-to-body="true">
                    <template v-for="(vnode, index) in slots">
                        <el-dropdown-item
                            v-if="index > 0"
                            :key="index"
                            :class="isDeleteBtn(vnode) ? 'table-operation-dropdown-item-delete' : 'table-operation-dropdown-item-normal'"
                            :disabled="(vnode.componentOptions && vnode.componentOptions.propsData && vnode.componentOptions.propsData.disabled === true) ? true : false"
                            @click.native="vnode.componentOptions.listeners.click">
                            <el-tooltip  :content="'如需操作，请联系相关运营人员'" placement="top" :key="index" v-if="disabled && disabled === true">
                                <el-button
                                    :class="isDeleteBtn(vnode) ? 'table-operation-btn-delete' : 'table-operation-btn-normal'"
                                    type="text"
                                    :disabled="true"
                                    :style="vnode.data.staticStyle"
                                >{{ vnode.elm.innerText }}</el-button>
                            </el-tooltip>
                            <el-button
                                v-else
                                :class="isDeleteBtn(vnode) ? 'table-operation-btn-delete' : 'table-operation-btn-normal'"
                                type="text"
                                :disabled="(vnode.componentOptions && vnode.componentOptions.propsData && vnode.componentOptions.propsData.disabled === true) ? true : false"
                                :style="vnode.data.staticStyle"
                            >{{ vnode.elm.innerText }}</el-button>
                            
                        </el-dropdown-item>
                    </template>
                </el-dropdown-menu>
            </el-dropdown>  
            
           
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableOperation',
    components: {},
    props: ['item', 'scope','disabled'],
    data() {
        return {
            slots: [],
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        // console.log("[TableOperation]this.$slots.default:", this.$slots.default)
        if (!this.$slots.default) return
        let slots = []
        for (let vnode of this.$slots.default) {
            if (vnode.elm.clientWidth > 0) {
                if (vnode.tag.indexOf("ElButton") == -1 && vnode.tag.indexOf("XkButton") == -1) {
                    slots = []
                    break
                }
                slots.push(vnode)
            }
        }
        this.slots = slots
        console.log("[TableOperation]this.slots:", this.slots)
        this.$forceUpdate();
    },
    methods: {
        isDeleteBtn(vnode) {
            return vnode.elm.innerText.indexOf('删除') > -1
        }
    }
}
</script>

<style lang="scss" scoped>
.table-operation {
    .el-button {
        font-size: 14px !important;
        padding: 0 !important;
        /* height: auto; */
    }

    .table-operation-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .table-operation-more {
        display: flex;
        align-items: center;
        cursor: pointer;

        .table-operation-more-label {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2B2F33;
            line-height: 1;
            margin-left: 10px;
            white-space: nowrap;
        }
    }
    .table-operation-more:hover {
        .table-operation-more-label,
        .table-operation-more-icon {
            color: #3C7FFF;
        }
    }

    .table-operation-dropdown-item-normal:hover {
        background-color: #DAE6F2;
    }
    .table-operation-dropdown-item-delete:hover {
        background-color: #F5DEDC;
    }

    .table-operation-btn-primary:not(.is-disabled) {
        color: #3C7FFF !important;
    }
    .table-operation-btn-delete:not(.is-disabled) {
        color: #E63A2E !important;
    }
    .table-operation-btn-normal:not(.is-disabled) {
        color: #2B2F33 !important;
    }
}
</style>

<style lang="scss">
.table-operation-dropdown-menu {
    .el-button {
        font-size: 14px !important;
        padding: 0 !important;
    }

    .table-operation-dropdown-item-normal:hover {
        background-color: #DAE6F2;
    }
    .table-operation-dropdown-item-delete:hover {
        background-color: #F5DEDC;
    }

    .table-operation-btn-primary:not(.is-disabled) {
        color: #3C7FFF !important;
    }
    .table-operation-btn-delete:not(.is-disabled) {
        color: #E63A2E !important;
    }
    .table-operation-btn-normal:not(.is-disabled) {
        color: #2B2F33 !important;
    }
}
</style>
